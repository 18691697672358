<template>
  <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17" style="min-height:62vh;" >
            <a-row type="flex" :gutter="20" class="demand-outer-box">
              <div class="sub-component-background-color" style="min-height: 75.9vh">
                <div class="inner-box"><span>需求库</span></div>
                <a-col  :span="12"  v-for="(item,index) in tableData" :key="index"  >
                  <div class="">
                    <template>
                      <!--<a-empty v-if="JSON.stringify(tableData)==='{}'" class="empty-data"/>-->
                      <div class="demand-project-warehouse">
                        <!--                    <span class="type-title"/>-->
                        <template  v-if="item.length>0">
                          <span class="type-name">{{item[0].itemText}}</span>
                          <span class="mores" @click="jump(item,index)">更多>></span>
                          <!-- content-box :scroolData="item" @detail="projectDetails"/>-->
                        </template>

                      </div>
                      <div class="link-lsit">
                        <div v-for="(items,indexs) in item" :key="indexs" class="link" @click="projectDetails(items.id)">
                          <span style="color:red;padding-right: 8px">·</span>
                          <span href="link" style="color: #0c4dc5">{{ items.productName }}</span>
                        </div>
                        <a-empty v-if="item.length==0" class="empty-data"/>
                      </div>
                    </template>
                  </div>

                </a-col>
              </div>
            </a-row>

        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
  </div>
</template>

<script>
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getProjectList, domainQueryId } from '@/api/project'
import { getDict } from '@/api/index'
import { Station } from '@/mixins/station'

export default {
  name: 'DemandTypeList',
  mixins: [Station],
  components: {
    linkBox,
    FindDemand
  },
  data() {
    return {
      tableData: [],
      technicalProjectContent: []
    }
  },
  methods: {
    /**
     * @description: 跳转list
     * @param {String} dic
     */
    jump(dic, index) {
      if (this.stationId !== '') {
        this.$router.push({ name: 'result', params: { dic: dic }, query: { stationId: this.stationId, mainResearchAreas: index }})
      } else {
        this.$router.push({ name: 'result', params: { dic: dic }, query: { mainResearchAreas: index }})
      }
    },
    /**
     * @description:项目需求详情
     */
    projectDetails(id) {
      domainQueryId({ id: id }).then(res => {
        if (this.stationId !== '') {
          this.$router.push({ name: 'detail', params: res.result, query: { stationId: this.stationId }})
        } else {
          this.$router.push({ name: 'detail', params: res.result })
        }
      })
    }
  },
  activated() {
    // this.stationId = this.$route.query.stationId
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    // 判断是否为子站
    this.getStationId()
    // getProjectList({ stationId: this.stationId }).then(res => {
    //   // this.tableData = res.result
    // })
    const params = {
      stationId: this.stationId,
      type: '2'
    }
    // 获取需求列表
    getProjectList(params).then(res => {
      this.tableData = res.result
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~@assets/css/border.scss";
@import "~@assets/css/scroll.css";
@import "../../assets/css/newsInformation.css";

// .project-warehouse .type-name {
//   font-size: 20px;
// }

.demand-outer-box {
  width: 100%;
  //min-height: 216px;
  // margin: 0 auto;
  //border: 1px solid #bbd3ef;
  //border-top: 1px solid #5f95c3;
  margin-bottom: 10px;
  padding-left: 20px;
  .inner-box {
    width: 100%;
    //height: 39px;
    //background: url("~@assets/img/bk.jpg") no-repeat;
    background-size: 100%;
    font-size: 20px;
    border-bottom: 1px solid #5f95c3;
    margin-bottom: 20px;
    span {
      font-weight: bold;
      background-image: -webkit-linear-gradient(bottom, #064a9b, #59a6f8);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 39px;
      padding-left: 20px;
    }
  }
  .divider {
    width: 94%;
    height: 10px;
    text-align: right;
    margin: 8px 0px 0px 10px;
    background: url("~@assets/img/divider.jpg") no-repeat;
    background-size: 100% 10px;
    padding-top: 1px;
    span {
      margin-right: -40px;
      font-size: 12px;
      line-height: 8px;
      font-family: "宋体";
      vertical-align: top;
      color: rgb(148, 142, 142);
      margin-bottom: 2px;
      cursor: pointer;
    }
  }
  .demand-project-warehouse {
    width: 100%;
    padding: 10px 4px;
    min-height: 41px;
    background-color: #135fad;
    border-radius: 8px;
    display: flex;
    .type-title {
      padding-left: 14px;
      line-height: 24px;
      font-size: 24px;
      color: #135fad;
    }
    .type-name {
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      flex:10
    }

    .mores {
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      width: 100%;
      text-align: right;
      flex:2;
      cursor: pointer !important;
    }

  }
.link-lsit{
  width: 100%;
  min-height: 180px;
  padding: 10px 5px;
  margin: 10px 0;
  font-size: 14px;
  border-radius: 10px;
background-color: #faf1f1;
  .link{
    width:  100%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    padding-bottom: 10px;
    cursor: pointer !important;
  }
}
}

</style>
